import React from "react";
import Header from "./Header";
import PrivacyPolicy from "./PrivacyPolicy";

function index({location}) {
  return (
    <div>
      <Header />
      <PrivacyPolicy />
    </div>
  );
}

export default index;
