import React from "react";

function PrivacyPolicy() {
  return (
    <div className="max-w-7xl m-auto px-8 lg:py-24 md:py-16 py-12 ">
      <p className="md:mb-12 mb-4">
        Last updated June 10, 2021 (modified to delete Privacy Shield). Click
        here for archived versions.
      </p>

      <p className="mb-3">
        We know your privacy is important to you, and we want you to know it’s
        important to us too. This Privacy Policy is meant to explain our
        practices when it comes to your use of our products, services, and
        websites. We encourage you to take the time to review it carefully, to
        understand what information we collect, why we collect it, and what we
        do with it. Below, we cover the following topics:
      </p>
      <ul className="list-disc pl-6">
        <li className="mb-2">Who we are</li>
        <li className="mb-2">What information we collect</li>
        <li className="mb-2">
          Why we collect your information and how we use it
        </li>
        <li className="mb-2">
          Why we collect your information and how we use it
        </li>
        <li className="mb-2">
          EEA users and our “lawful bases” for using their data
        </li>
        <li className="mb-2">Who we share your data with and why</li>
        <li className="mb-2">How we protect your information</li>
        <li className="mb-2">
          Where we store your information and international transfers
        </li>
        <li className="mb-2">How long we keep your information</li>
        <li className="mb-2">How to access and control your information</li>
        <li className="mb-2">Other important information</li>
        <li className="mb-2">How to contact us</li>
      </ul>
      <div>
        <h1 className="font-bold text-lg md:pt-7 pt-4 pb-2"> Who we are</h1>
        <p>
          Vonage Holdings Corp., including its corporate affiliates, is a global
          provider of cloud communications. For our business and residential
          customers, we offer a complete and fully-configured unified
          communications solution for the delivery of end-to-end communications
          via voice, video, text, mobile applications, and collaboration tools.
          Through our subsidiary Nexmo Inc., we provide our communications
          services in programmable modules delivered via APIs and SDKs, enabling
          our customers to incorporate these services into their own
          applications to serve their own customers.
        </p>
        <p className="py-4">
          If you are a customer located in the European Economic Area (“EEA”),
          the following corporate affiliates of Vonage Holdings Corp., may also
          process your personal data as joint controllers with Vonage Holdings
          Corp., including for the provision of their products and services,
          marketing and support:
        </p>
        <ul className="list-disc pl-6 py-5">
          <li className="mb-2">
            Nexmo Limited (company number 07935346) with a registered address of
            25 Canada Square Level 37, London, England, E14 5LQ
          </li>
          <li className="mb-2">
            Vonage Business Limited (company number 03602868) with a registered
            address of 25 Canada Square Level 37, London, England E14 5LQ
          </li>
        </ul>
        <p>
          This Privacy Policy applies to our customers, prospective customers,
          our customers’ customers (both direct and indirect), and visitors to
          our company websites, including but not limited to www.vonage.com,
          www.vonage.co.uk, www.vonage.com.au and www.phonerequests.com.{" "}
          <span className="font-bold text-lg">
            If you do not agree with this Privacy Policy, do not access or use
            our services or interact with any other aspect of our business.
          </span>
          Note: if you’re a user of an application that is integrated or built
          using Vonage’s products and services, that application’s privacy
          policy will describe how it collects, uses, stores, and shares your
          data. We are not responsible for the privacy policies or actions of
          our customers.
        </p>
      </div>
      <div>
        <h1 className="font-bold text-lg md:pt-7 pt-4 pb-2">
          What information we collect
        </h1>
        <p>We collect your information in the following ways:</p>
        <ul className="list-disc">
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Information you provide directly to us.
            </span>{" "}
            When you subscribe to our newsletters, register for one of our
            webinars, sign up for our services, request us to contact you, or
            through other interactions with us, we may ask you for certain
            personal information, such as your name, birthdate, postal address,
            e-mail address, telephone number, company name, job title, or
            payment information. When you request support from us, we may also
            collect information from you such as contact information,
            documentation, screenshots, or other information you or we may
            believe is helpful to solving the issue. When you speak with our
            customer service or sales representative on the phone, your calls
            may be recorded and/or monitored for quality assurance,training and
            research purposes. In certain circumstances, we may collect your
            Social Security number from you for a limited purpose, such as for
            tax reporting relating to a payment for a customer referral or to
            facilitate an international money transfer.{" "}
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Information we collect automatically when you visit our websites.
            </span>{" "}
            We and our third-party partners, such as our advertising and
            analytics partners, collect information about your visits to our
            websites and your interactions with our ads or content, together
            with information such as your IP address, cookies, and other
            tracking technologies (e.g., web beacons, device identifiers, and
            pixels). For more information, please see our Cookies and Tracking
            Notice, which includes information on how to control or opt out of
            these cookies and tracking technologies.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Information we get from third parties
            </span>{" "}
            Third party sources of information include:
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Third party services and social media.
            </span>{" "}
            If you create an account or access our services through your account
            with a third-party service, such as Facebook, Google, LinkedIn,
            GitHub or Twitter, or use a social media feature through our
            services, certain personal data from those third-party social media
            services may be shared with us, such as your interests, “likes,” and
            friends list. We may use this information, for example, to
            personalize your Vonage experiences and marketing communications, to
            enhance our services, and to better serve you. You can control this
            data sharing via options in your social media accounts. We may also
            collect information from third party services that are integrated
            with our services, such as third-party CRMs or other applications
            that you authorize our services to connect with.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">Credit agencies.</span> We may
            obtain your credit information from third party reporting agencies
            in order to control our own credit risk in onboarding new customers.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Demographic, lead, and interest data.
            </span>{" "}
            We may obtain information from outside companies such as those that
            collect customer information including demographic and interest
            data. Examples of this information include your employment status,
            your job title with your current employer, and your business contact
            information. We use this data and combine it with other information
            we have about you to help us predict your preferences and to direct
            marketing offers that might be more relevant to you. We also obtain
            where permitted by law contact information and other marketing lead
            information from third parties, website “refer-a-friend” options or
            social media platforms and may combine it with information we have
            to contact you or direct Vonage marketing offers to you.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Information about our customers’ users.
            </span>{" "}
            Our customers and other third parties may also provide us with
            personal information about our customers’ users and others. For
            example, we may receive personal information and other information
            from our customers, message senders, mobile network operators,
            databases with information relevant to mobile telephone numbers
            submitted to our services, and other third parties. This information
            may include, without limitation, telephone numbers, telephone
            numbers’ validity, type (e.g., mobile, landline, etc.),
            corresponding device status (e.g., whether or not it is currently
            available for messaging), roaming status, carrier, country of
            location, and whether or not the number has been ported and is used
            to help us provide our services.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Information collected in connection with your use of services
              delivered via our platform.
            </span>{" "}
            We and our service providers may collect information in connection
            with your use of communications services delivered via our platform.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Communications usage information.{" "}
            </span>{" "}
            This includes information about your communications delivered via
            our platform such as the time and duration of usage, source and
            destination identifiers, completion status, location, IP address,
            and amount of usage.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">Communications content</span> To
            enable you to send and receive communications via our platform, we
            need to be able to handle the content of the messages, calls, and
            other communications channels used by you. This also includes, for
            example, voicemails and call recordings recorded via our services.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">Device information.</span> Where
            we have provided end user equipment to you, such as an analog
            telephone adapter or a VoIP phone, or you have installed our
            software on your device, we collect device-specific information from
            you. This includes, for example, your hardware model, operating
            system version, firmware, browser information, device and network
            configuration, device identifier, IP address, device performance,
            signal strength, call quality, telemetry, and mobile or wireless
            network information. We use the device information we collect in
            order to deliver and improve our services.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Your contact lists and address book.
            </span>{" "}
            If you use our mobile apps, we may request your permission to access
            and store the contact list or address book maintained on your mobile
            phone, tablet, or other broadband-connected device. Your contact
            list is considered your personal data. We may use your contact list
            information to facilitate certain services where selected by you
            such as to enable you to make calls easily and to facilitate calls,
            texts, and other services. We may also facilitate the delivery of
            messages to individuals in your contact list that you wish to invite
            to download our mobile apps so that you can utilize our services
            with these selected individuals, although it is always your choice
            to send invites to such individuals. We will not use your contact
            list information for other purposes without first notifying you of
            the proposed use. You do not have to allow us to access your contact
            list information, but if you do not, certain features of our mobile
            apps may not be available to you. You may at any time opt out from
            allowing this access via the privacy settings on your device.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Mobile device camera, microphone and photos.
            </span>{" "}
            If you use our mobile apps, we may request your permission to access
            the camera, microphone, and photos on your mobile device, to make
            and receive voice and video calls and messages and to send photos to
            others. You do not have to allow us to access these functions of
            your device, but if you do not, certain features of our mobile apps
            may not be available to you. You may at any time opt out from
            allowing this access via the privacy settings on your device.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              Your location information.
            </span>{" "}
            If your mobile device is equipped with GPS or can connect with
            wireless access points or hot spots, or if your mobile device is
            also a phone that communicates with cell towers or satellites, then
            your mobile device is able to use these features to determine its
            precise geographic location. You may use the mobile app to do a
            one-time share of your precise geographic location through a message
            in a chat session by pressing the location attachment button within
            the mobile app (a “Location Share Event”). We will retain the
            Location Share Event within your chat session on the mobile app
            until you delete the message. Your precise geographic location,
            including a Location Share Event, is considered your personal data.
            To the extent our mobile apps collect precise geographic location,
            you may at any time opt out from further allowing us to have access
            to your mobile device’s precise location information via the mobile
            app’s location settings on your mobile device.
          </li>
        </ul>
      </div>
      <div>
        <h1 className="font-bold text-lg md:pt-7 pt-4 pb-2">
          Why we collect your information and how we use it
        </h1>
        <p>
          How we use the information we collect depends on which of our services
          you use, how you use them, and specific preferences you may have
          communicated to us. We list below the specific purposes for which we
          collect your information.
        </p>
        <ul className="list-disc">
          <li className="text-base font-normal">
            <span className="font-bold text-lg">To deliver our services.</span>{" "}
            We use your information because it is necessary to perform our
            obligations in delivering our services to our customers. This
            includes delivering your communications to the intended end user,
            processing transactions with you (such as billing), authenticating
            you when you log into our platform, providing customer support, and
            operating and maintaining our services. We also need your
            information to communicate with you about the services, including
            registration confirmations, purchase confirmations, expiration or
            renewal reminders, responding to your requests, and sending you
            notices, updates, security alerts, administrative messages, and
            other communications necessary to usage of the services.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              To carry out core activities relating to our services.
            </span>{" "}
            To effectively deliver our services to you, we use your information
            to engage in important supporting activities such as:
            <ul className="list-disc">
              <li className="text-base font-normal">
                billing and collections, including maintenance of records in the
                event of a subsequent billing dispute;
              </li>
              <li className="text-base font-normal">
                preventing fraud, violations of our acceptable use policies, and
                unlawful activities;
              </li>
              <li className="text-base font-normal">
                troubleshooting, quality control, and analytics; and;
              </li>
              <li className="text-base font-normal">
                monitoring the performance of our systems and platform.
              </li>
            </ul>
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              For research and development.
            </span>{" "}
            We are constantly looking for ways to improve our services, to make
            them more reliable, secure, and useful to you and our users
            generally. We use data regarding our users’ communications on our
            platform to understand how our services are performing and how they
            are being used in order to identify areas where we can do better.
            For instance, we may use message delivery and call connection
            information to gauge the effectiveness of our routing to ensure that
            your messages are delivered and your calls are connected. We and our
            service providers may use your information to assess the level of
            interest in, and use of, our services, our communications to our
            customers, and our other messaging campaigns, both on an individual
            basis and in the aggregate. We also use information about your use
            of our websites to understand how our website visitors are using our
            websites. Among other things, this usage information, along with
            tracking technologies, enables third-party analytics companies, such
            as Google Analytics, to generate analytics reports on the usage of
            our services. To opt out of your usage information being included in
            our Google Analytics reports, you may follow these instructions.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              To market, promote, and drive engagement of our products and
              services.
            </span>{" "}
            We use data about you to send promotional communications that may be
            of specific interest to you. Based on information we collect about
            you, we may decide whether and how to promote certain of our
            products or services to you over others. These communications are to
            drive your engagement and maximize the value of our services to you.
            To perform the above functions and others described in this Privacy
            Policy, we may match information collected from you through
            different means or at different times, including personal data and
            usage information, and use such information along with information
            obtained from other sources (including third parties) such as
            contact information, demographic information, and personal interest
            information. Subject to your ability to opt out, by providing your
            contact information to us, you consent to receive e-mail messages,
            text messages, phone calls, faxes and postal mail, including that of
            a promotional nature, from Vonage. Where local law permits, you
            consent to receive phone calls from Vonage and its affiliates even
            if your phone number is listed on “do not call” registries. Where
            local law permits, an auto-dialer and/or artificial or prerecorded
            message may be used to make calls to you. You may not consent on
            behalf of someone else or provide someone else’s contact
            information. You are not required to agree to promotional
            communications in order to purchase goods or services from us. You
            can control whether you receive these kinds of communications as
            described below in Opt out of communications.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              To comply with legal requirements.
            </span>{" "}
            Applicable laws or regulations may require our processing of your
            data, such as laws mandating retention of communications data.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              To protect our legitimate business interests and legal rights.
            </span>{" "}
            Where we believe it is necessary to protect our legal rights,
            interests and the interests of others, we use information about you
            in connection with legal claims, compliance, regulatory, and audit
            functions, and disclosures in connection with the acquisition,
            merger or sale of a business.
          </li>
          <li className="text-base font-normal">
            <span className="font-bold text-lg">
              According to your explicit consent.
            </span>{" "}
            If we wish to use your information for certain purposes which
            require consent under applicable law, we will first seek out and
            obtain your consent. This may include, for example, testimonials or
            case studies that identify you in your individual capacity.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
